export const getEndDate = () => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  return Math.floor(now.getTime() / 1000)
}

export const getStartDate = (period) => {
  const now = new Date()
  switch (period) {
    case 'year':
      now.setFullYear(now.getFullYear() - 1)
      break
    case 'quarter':
      now.setMonth(now.getMonth() - 3)
      break
    case 'month':
      now.setMonth(now.getMonth() - 1)
      break
    case 'week':
      now.setDate(now.getDate() - 7)
      break
    default:
      break
  }
  now.setHours(0, 0, 0, 0)
  return Math.floor(now.getTime() / 1000)
}
