import React, { useState } from 'react'
import { Box } from '@mui/material'
import StatsProvider from '../../../../app/Stats/StatsProvider'
import LinearChart from '../../../../widgets/Chart/LinearChart'
import ToggleButtonIndicators from '../../../../widgets/ToggleButton/ToggleButtonIndicators'
import ContentToggleButtonLinear from '../../../../widgets/ToggleButton/ContentToggleButton/ContentToggleButtonLinear'
import PropTypes from 'prop-types'
import { getStartDate, getEndDate } from './datePeriodHelper'

const chartConfig = {
  firstChart: {
    period: 'month',
    detail: 'day',
    chartType: 'bar',
    includeEndDate: false,
  },
  secondChart: {
    period: 'quarter',
    detail: 'day',
    chartType: 'line',
    buttonConfig: ContentToggleButtonLinear,
    includeEndDate: true,
  },
}

function ChartWrapperInstallations({ type, value }) {
  const initialState = chartConfig[value] || {}
  const [state, setState] = useState(initialState)

  const handleToggleChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const { buttonConfig } = chartConfig[value] || {}
  return (
    <>
      <Box my={2}>
        {buttonConfig && (
          <ToggleButtonIndicators
            setState={handleToggleChange}
            startState={state}
            buttonsGroupConfig={buttonConfig}
          />
        )}
      </Box>
      <StatsProvider
        type={type}
        query={{
          startDate: getStartDate(state.period),
          ...(chartConfig[value].includeEndDate && { endDate: getEndDate() }),
          segment: state.detail,
        }}
      >
        <LinearChart
          segment={state.detail}
          chartType={state.chartType}
          startDate={getStartDate(state.period)}
          endDate={getEndDate()}
        />
      </StatsProvider>
    </>
  )
}

ChartWrapperInstallations.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default ChartWrapperInstallations
