import React, { useState, useContext } from 'react'
import BuildsContext from '../../app/Settings/Builds/BuildsContext'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Box,
  CircularProgress,
  Tooltip,
  Chip,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined'
import HideSourceIcon from '@mui/icons-material/HideSource'
import api from '../../shared/api'
import EditDialogBuilds from '../EditDialog/EditDialogBuilds'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'
import ReloadIcon from '@mui/icons-material/CachedRounded'
import { useAuth } from '../../app/OAuth/Provider'
import { useFeatures } from '../../app/FeatureToggle'
import SnackbarNotification from '../../shared/ui/Components/SnackbarNotification'
import { formatFileSize } from '../../shared/units'
import BuildVersion from '../../shared/ui/BuildVersion'
import { DownloadBuildButton } from '../../shared/ui/DownloadButton'
import ChartWrapperCounter from '../../shared/ui/Components/ChartWrapper/ChartWrapperCounter'

const BuildsTable = ({ selectedBranch, selectedApp }) => {
  const { getToken } = useAuth()

  const { data, setData, loading, refreshBuilds } = useContext(BuildsContext)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)
  const [isEditingId, setEditingId] = useState(null)

  const [expandedRows, setExpandedRows] = useState({})
  const [deleted, setDeleted] = useState({})
  const [deletingBuild, setDeletingBuild] = useState({ id: null, full: '' })
  const [showConfirm, setShowConfirm] = useState(false)

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarNotification, setSnackbarNotification] = useState({
    message: '',
    severity: 'success',
  })

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const handleConfirmRemove = (row) => {
    setDeletingBuild(row)
    setShowConfirm(true)
  }

  const handleRemove = () => {
    getToken().then((accessToken) => {
      setEditingId(deletingBuild.id)
      api
        .delete(`/v1/settings/builds/remove/${deletingBuild.id}`, {
          authorization: accessToken,
        })
        .then((_) => {
          const newDeleted = {}
          for (const d in deleted) {
            newDeleted[d] = true
          }
          newDeleted[deletingBuild.id] = true
          setDeleted(newDeleted)
          setSnackbarNotification({
            message: 'Успешное удаление сборки',
            severity: 'success',
          })
          setSnackbarOpen(true)
        })
        .catch((e) => {
          console.log(e)
          setSnackbarNotification({
            message: 'Ошибка удаления сборки',
            severity: 'error',
          })
          setSnackbarOpen(true)
        })
        .finally(() => {
          setEditingId(null)
        })
    })
  }

  const handleEditClick = (row) => {
    setCurrentRow(row)
    setDialogOpen(true)
  }

  const handleSave = (updatedRow) => {
    const updatedData = data.map((row) =>
      row.id === updatedRow.id ? updatedRow : row
    )
    setData(updatedData)
    setEditingId(updatedRow.id)
    setDialogOpen(false)
    getToken().then((accessToken) => {
      api
        .put(`/v1/settings/builds/update/${updatedRow.id}`, updatedRow, {
          authorization: accessToken,
        })
        .then(() => {
          setSnackbarNotification({
            message: 'Данные успешно изменены',
            severity: 'success',
          })
          setSnackbarOpen(true)
        })
        .catch((error) => {
          console.error('Ошибка изменения данных:', error)
          setSnackbarNotification({
            message: 'Ошибка изменения данных',
            severity: 'success',
          })
          setSnackbarOpen(true)
        })
        .finally(() => {
          setEditingId(null)
        })
    })
  }

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text
    return `${text.slice(0, maxLength)}...`
  }

  const handleTextClick = (id) => {
    setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }))
  }

  const filteredData = data?.filter((row) => {
    const selectedBranchFinal = selectedBranch || 'test'
    const branchMatches =
      selectedBranchFinal === 'any' || row.thread === selectedBranchFinal
    const appMatches = selectedApp === 'any' || row.app === selectedApp
    return branchMatches && appMatches
  })

  const handleReload = () => {
    refreshBuilds()
  }

  const features = useFeatures()

  return (
    <>
      <ConfirmDialog
        show={showConfirm}
        message={
          deletingBuild.thread === 'release'
            ? `ВНИМАНИЕ!\nВы удаляете РЕЛИЗНУЮ сборку!\n\nУдалить ${deletingBuild.full}?`
            : `Удалить сборку ${deletingBuild.full}?`
        }
        onHide={() => setShowConfirm(false)}
        accept={handleRemove}
      />
      {currentRow && (
        <EditDialogBuilds
          show={dialogOpen}
          onHide={() => setDialogOpen(false)}
          onSave={handleSave}
          data={currentRow}
          setData={setCurrentRow}
        />
      )}
      <SnackbarNotification
        notification={snackbarNotification}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="builds table">
          <TableHead>
            <TableRow>
              {features.includes('BUILD_PUBLISH') ? (
                <TableCell sx={{ width: '6%' }}>Опубликовано</TableCell>
              ) : null}
              <TableCell>Дата</TableCell>
              <TableCell>Версия</TableCell>
              {selectedBranch === 'beta' && <TableCell>β-токен</TableCell>}
              <TableCell>Размер</TableCell>
              <TableCell>Скачиваний за 7 дней</TableCell>
              <TableCell>Изменения</TableCell>
              <TableCell>
                <Box
                  display={'flex'}
                  justifyContent={'end'}
                  alignItems={'center'}
                >
                  {loading ? (
                    <Box>
                      <CircularProgress size={24} />
                    </Box>
                  ) : (
                    <Tooltip title="Обновить сборки">
                      <IconButton onClick={handleReload}>
                        <ReloadIcon color={'primary'} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{
                    textAlign: 'center',
                    color: '#999',
                    height: 70,
                  }}
                >
                  Список пуст
                </TableCell>
              </TableRow>
            ) : null}
            {filteredData.map((row) =>
              deleted[row.id] ? (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell
                    colSpan={5}
                    sx={{
                      textAlign: 'center',
                      color: '#999',
                      height: 70,
                      background:
                        'linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.03) 50%, rgba(0,0,0,0))',
                    }}
                  >
                    Сборка удалена
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {features.includes('BUILD_PUBLISH') ? (
                    <TableCell>
                      {row.publish ? (
                        <IconButton aria-label="done">
                          <DoneOutlineOutlinedIcon />
                        </IconButton>
                      ) : (
                        <IconButton aria-label="hide" sx={{ color: '#b71c1c' }}>
                          <HideSourceIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  ) : null}
                  <TableCell sx={{ color: '#555' }}>
                    {row.creationDate}
                  </TableCell>
                  <TableCell>
                    <BuildVersion fullVersion={row.full} />
                  </TableCell>
                  {selectedBranch === 'beta' && (
                    <TableCell>
                      <Tooltip title={row.token}>
                        <Chip label={row.token.substr(0, 8)} />
                      </Tooltip>
                    </TableCell>
                  )}
                  <TableCell>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        columnGap: '7px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {formatFileSize(row.filesize)}
                      <DownloadBuildButton id={row.id} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <ChartWrapperCounter
                      id={row.id}
                      code={selectedApp}
                      thread={selectedBranch}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography
                      onClick={() => handleTextClick(row.id)}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      {expandedRows[row.id]
                        ? row.changes.split('\n').map((paragraph, index) => (
                            <React.Fragment key={index}>
                              {paragraph}
                              <br />
                            </React.Fragment>
                          ))
                        : truncateText(row.changes, 50)}
                    </Typography>
                  </TableCell>
                  <TableCell align={'right'} sx={{ whiteSpace: 'nowrap' }}>
                    {isEditingId === row.id ? (
                      <CircularProgress size={24} />
                    ) : (
                      <>
                        <Tooltip title="Изменить">
                          <IconButton
                            aria-label="edit"
                            onClick={() => handleEditClick(row)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Удалить">
                          <IconButton
                            aria-label="delete"
                            color={'error'}
                            onClick={() => handleConfirmRemove(row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

BuildsTable.propTypes = {
  selectedBranch: PropTypes.string,
  selectedApp: PropTypes.string,
}

export default BuildsTable
