import React, { useMemo } from 'react'
import AreaChartCounter from '../../../../widgets/Chart/AreaChartCounter'
import PropTypes from 'prop-types'
import BuildCounterProvider from '../../../../app/Settings/Builds/BuildCounterProvider'
import { getStartDate, getEndDate } from './datePeriodHelper'

function ChartWrapperCounter({ code, thread, id }) {
  const query = useMemo(
    () => ({
      code: code,
      thread: thread,
      startDate: getStartDate('week'),
      endDate: getEndDate(),
    }),
    [code, thread]
  )

  return (
    <BuildCounterProvider query={query}>
      <AreaChartCounter
        startDate={query.startDate}
        endDate={query.endDate}
        id={id}
      />
    </BuildCounterProvider>
  )
}

ChartWrapperCounter.propTypes = {
  code: PropTypes.string.isRequired,
  thread: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default ChartWrapperCounter
