import React from 'react'
import { Typography } from '@mui/material'
import ChartWrapperInstallations from '../../shared/ui/Components/ChartWrapper/ChartWrapperInstallations'
import ContentPaper from '../../shared/ui/Components/ContentPaper'
const chartConfig = [
  {
    value: 'firstChart',
    title: 'Количество установок по дням',
    type: 'installations',
  },
  {
    value: 'secondChart',
    title: 'Количество активных установок',
    type: 'installations/active',
  },
]

function ListChartsInstallations() {
  return chartConfig.map((chart) => (
    <ContentPaper key={chart.value}>
      <Typography variant="h6">{chart.title}</Typography>
      <ChartWrapperInstallations type={chart.type} value={chart.value} />
    </ContentPaper>
  ))
}

export default ListChartsInstallations
